<template>
  <div>
    <center>
       <img :src="(produto.foto ? produto.foto : 'off.jpg')"  style="width: 100%;" class="img-thumbnail">
    </center>
     <div class="row">
      <div class="col-12 mg-t-10">
        <span class="tx-18"><strong>{{produto.nome}}</strong></span>
        
        <div v-if="produto.ingredientes !== 'N'" class="tx-15" align="justify">
          <strong>Ingredientes: </strong>{{ produto.ingredientes }}
        </div>
        <div v-if="parseFloat(produto.valor) > 0" class="tx-18 tx-info mg-t-5">
          {{ formatPrice(parseFloat(produto.valor)) }}
        </div>
      </div>
    </div>
    <hr>
    <div v-for="groups in produto.groups.filter(a => a.opcionais.length > 0)" :key="groups.id">
    <br />
    <span v-if="groups.mensagem == true" style="color: #CC3300">Item obrigatorio selecione por favor. </span>
    <div  class="slim-card-title" style="color: #CC3300">{{groups.nomegrupo}}  <span v-if="groups.quantidade_minima > 0" style="color: #FF0000"> Obrigatório: {{groups.quantidade_minima}} </span></div>
      <div v-for="(protam) in groups.opcionais" :key="protam.id"  class="row mg-t-10" >
        <div  class="col-7 mg-t-10">
           <b-form-radio v-model="groups.selectRadio" v-if="groups.obrigatorio == 1" type="radio" :name="groups.nomegrupo" :value="protam.id" required> - <span>{{ protam.name }}</span> </b-form-radio>
           <b-form-checkbox   v-model="groups.selectCheck" v-else :name="protam.name" :value="protam.id"> - <span>{{ protam.name }}</span>
          </b-form-checkbox>
        </div>
        <div class="col-3 mg-t-10">
            {{ formatPrice(protam.valor) }}   
        </div>
        
      </div>
    </div>

         

    <div class="row mg-t-10">
        <div class="col-12">
          <label>Alguma observação?</label>
          <textarea v-model="obs" rows="3" name="observacoes" class="form-control"></textarea>
        </div>
      </div>
      <hr>

      <div class="row mg-t-10">
        <div class="col-6">
          <div class="input-group number-spinner">
            <div class="col-xs-3 col-xs-offset-3">
              <div class="input-group number-spinner">
                <span class="input-group-btn">
                  <button type="button" class="btn btn-default" data-dir="dwn"><i class="fa fa-minus" aria-hidden="true"></i></button>
                </span>
                <input v-model="quantidade" type="text" name="quantidade" class="form-control text-center" value="1" required>
                <span class="input-group-btn">
                  <button type="button" class="btn btn-default" data-dir="up"><i class="fa fa-plus" aria-hidden="true"></i></button>
                </span>
              </div>
            </div>
          </div>
        </div>
        
        <div class="col-6">
         <button  @click="adicionarItem()" class="btn btn-primary btn-block"><i style="color: white" class="fa fa-plus-square"></i> Incluir Pedido</button>
        </div>
      </div>  
  </div>
  
</template>

<script>


export default {
  props: ['produto', 'pedido'],
  
   data() {
    return {
      quantidade: 1,
      opcionais: [],
      produtos:[],
      obs:''
    };
  },
  methods: {

    formatPrice(price) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price);
    },

    generateIdPedido() {
      return Math.random().toString(36).substr(2, 9);
    },

    addOpcionais(grupo){
      grupo.mensagem = false
      grupo.selecionados = []
      grupo.selecionados.push(grupo.selectRadio)
    },

    addOpcionaisCheck(grupo, item){
      grupo.mensagem = false
      if(grupo.selecionados == null){
        grupo.selecionados = []
      }
      if(this.selectCheck){
        grupo.selecionados.push(item)
      }else {
        const index =  grupo.selecionados.indexOf(item);
        if (index > -1) {  
           grupo.selecionados.splice(index, 1);
        }

      }
    },
    uuidv4() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>(+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16))
    },
    adicionarItem() {
      
      let obrigatorios = this.produto.groups.filter(a => a.obrigatorio == 1 && a.opcionais.length > 0)
      if( obrigatorios != null && obrigatorios.filter(a => a.selectRadio == null ).length > 0){
        obrigatorios.filter(a => a.obrigatorio == 1).forEach(a => a.mensagem = true)
      }
     else {
        this.produtos.quantidade = this.quantidade 
        let prod =  Object.assign({}, this.produto)  
        prod.quantidade = this.quantidade
        prod.obs = this.obs
        prod.uuid = this.uuidv4()
        this.pedido(prod)
     }
    }
  }
};
</script>

<style scoped>
  @import "../../../src/assets/css/slim.css";

  *{
    color: #919191

  }
</style>
