<template>
  <div id="accordion" class="accordion-one mg-b-10" role="tablist" aria-multiselectable="true">
    <div class="card">
      <div class="card-header" role="tab" id="headingOne">
        <a @click="toggleCollapse" :aria-expanded="isCollapsed" href="#collapseOne" aria-controls="collapseOne" class="tx-gray-800 transition" style="color: #000000">
          <i class="fa fa-bars mg-r-10"></i> Menu
        </a>
      </div>
      <div :id="collapseId" :class="{ 'collapse show': isCollapsed, 'collapse': !isCollapsed }" role="tabpanel" aria-labelledby="headingOne">
        <div v-if="categorias && categorias.length > 0" class="card-body">
          <div class="card-people-list pd-5">
            <div class="media-list" style="margin-top: -10px">
              <div v-for="categoria in categorias" :key="categoria.id" class="media">
                <a :href="'#' + categoria.id">
                  <img :src="categoria.url || 'off.jpg'" class="categoria-img" alt="">
                </a>
                <div class="media-body">
                  <a :href="'#' + categoria.id" class="categoria-link">
                    {{ categoria.nome }} ({{ categoria.produtos != null ? categoria.produtos.length : 0}}) 
                  </a>
                </div>
                <a :href="'#' + categoria.id" class="categoria-link">
                  <i class="fa fa-chevron-circle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="card-body">
          <p>Não há categorias disponíveis.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { api } from '../../http/api';

export default {
  data() {
    return {
      isCollapsed: true,
      collapseId: 'collapseOne',
      categorias: [],
      idu: 4
    };
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    async fetchCategorias() {
      try {
        let companyName = this.$route.params.companyName
        let res = await axios.get(`${api.url}/company/check/${companyName}`);
        this.idu = res.data.id
        const response = await  axios.get(`${api.url}/category/client/${this.idu}`);
        this.categorias = response.data;
        await Promise.all(this.categorias.map(categoria => this.fetchProdutosParaCategorias(categoria)));
      } catch (error) {
        console.error("Erro ao buscar categorias:", error);
      }
    },
    async fetchProdutosParaCategorias(categoria) {
      try  {
        const response = await axios.get(`${api.url}/product/client/${this.idu}/${categoria.id}`);
        this.$set(categoria, 'produtos', response.data);
      } catch (error) {
        console.error(`Erro ao buscar produtos para categoria ${categoria.id}:`, error);
      }
    }
  },
  async created() {
    await this.fetchCategorias();
  }
};
</script>

<style>
.categoria-img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}
.categoria-link {
  color: #000000;
  text-decoration: none;
}
</style>
