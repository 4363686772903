import Vue from 'vue'
import VueRouter from 'vue-router'
import VueLocalStorage from 'vue-localstorage'
import IndexComponent from '@/components/IndexComponent'
import DetalhesProduto from '@/components/modal/DetalhesProduto'
import EnderecoPedidoComponent from '@/components/endereco/EnderecoPedidoComponent'
import SiteView from '@/components/SiteView'
import CadastroView from '@/components/CadastroView'

Vue.use(VueLocalStorage, {
    name: 'ls',
    bind: true
  })
  
  Vue.use(VueRouter)
  let routes =[
    {
        path: '/detalhes-produto',
        name: 'detalhes-produto',
        component: DetalhesProduto,
        // beforeEnter: (to, from, next) => {
        //   if(permissions.isSendTemplateScheduler()){
        //     next()
        //   }
        //   else {
        //     next({ name: 'home' })
        //   }
        // },
      },
    {
        path: '/informacoes-pedido',
        name: 'informacoes-pedido',
        component: EnderecoPedidoComponent,
        // beforeEnter: (to, from, next) => {
        //   if(permissions.isSendTemplateScheduler()){
        //     next()
        //   }
        //   else {
        //     next({ name: 'home' })
        //   }
        // },
      },
     
      {
        path: '/',
        name: 'site',
        component: SiteView,
      
      },
      {
        path: '/cadastro',
        name: 'cadastro',
        component: CadastroView,
      
      },
      {
        path: '/:companyName',
        name: 'index',
        component: IndexComponent,
      
      },
    {
        path: '/home/:companyName',
        name: 'home',
        component: IndexComponent,
        // beforeEnter: (to, from, next) => {
        //   if(permissions.isSendTemplateScheduler()){
        //     next()
        //   }
        //   else {
        //     next({ name: 'home' })
        //   }
        // },
      }]

      const router = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        hashbang: false,
        history: true,
        linkActiveClass: "active",
        routes
      });

export default router;