import Vue from 'vue';
import App from './App.vue';
import BootstrapVue from "bootstrap-vue"
import router from './router';
import '@fortawesome/fontawesome-free/css/all.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

Vue.config.productionTip = false;

Vue.use(BootstrapVue,{
  theme: {
    primary: '#ff00ff',
    secondary: '#b0bec5',
    accent: '#8c9eff',
    error: '#b71c1c'
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
