<template>
  <div>
    <div class="mg-b-10" align="center"><img :src="estilo.banner" class="img-fluid" /></div>
    <div v-for="categoria in categorias" :key="categoria.id" class="card card-people-list pd-15 mg-b-10" :id="categoria.id">
      <div class="slim-card-title"><i class="fa fa-caret-right"></i> {{ categoria.nome }}</div>
      <div class="media-list">
        <div v-for="produto in categoria.produtos" :key="produto.id" class="media">
          <a v-if="aberto" href="#" class="view_data" :id="produto.id">
            <img :src="(produto.foto ? produto.foto : 'off.jpg')" style="width: 80px; height: 65px;" class="img-thumbnail">
          </a>
          <img v-else :src=" (produto.foto ? produto.foto : 'off.jpg')" style="width: 80px; height: 65px;" class="img-thumbnail"> 
           <div class="media-body">
            <a v-if="aberto" href="#" class="view_data" :id="produto.id" style="color: #666666"> 
                <span class="tx-15"><strong>{{ produto.nome }}</strong></span>
                <p class="tx-12 mg-r-5">{{ produto.ingredientes == 'N' ? '': produto.ingredientes }}</p>
                <p class="tx-14"><strong>{{parseFloat(produto.valor) > 0.00 ? 'R$ ' +parseFloat(produto.valor).toFixed(2).replace('.', ',') : '' }}</strong></p>
            </a>
            </div>
            <div align="left">
              <div @click="incluir(produto)" v-if="aberto && (produto.groups == null || produto.groups.length == 0) && parseFloat(produto.valor) > 0.00" type="button" class="btn btn-success btn-sm view_data" :id="produto.id">
                <i class="fa fa-cart-plus mg-r-5"></i> Incluir
              </div>
              <div @click="incluir(produto)" v-else-if="aberto && (produto.groups != null && produto.groups.length > 0) " type="button" class="btn btn-info btn-sm view_data" :id="produto.id">
                <i class="fa fa-plus-circle mg-r-5"></i> Opções
              </div>
              <div v-else class="btn btn-danger btn-sm">
                <i class="fa fa-window-close"></i>
              </div>
            </div>
          </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import axios from 'axios';
import { api } from '../../http/api';
export default {
  props: ['openModal', 'cpro', 'opcionaisxk', 'qntta', 'qntgrupos', 'id_cliente', 'info', 'estilo'],
  
  methods: {
    incluir(produto) {
      this.openModal(produto);
    },
    fetchEmpresa() {
      axios.get(`${api.url}/auth/company/client/info/${this.idu}`)
        .then(response => {
          this.dadosempresa = response.data;
          this.aberto = this.dadosempresa.delivery == 1
        })
        .catch(error => {
          console.error("Erro ao buscar dados da empresa:", error);
        });
        
    },
   async fetchCategorias() {
     let response =  await axios.get(`${api.url}/category/client/${this.idu}`)
        
        response.data.forEach(a => {a.produtos =  this.fetchProdutosParaCategorias(a)})
    },
    async fetchProdutosParaCategorias(categoria) {
        let respnse =  await axios.get(`${api.url}/product/client/${this.idu}/${categoria.id}`);
        categoria.produtos = respnse.data
        categoria.produtos.forEach(a => {
            this.fetchGroups(a)
        })
        this.categorias.push(categoria)
    },
    async fetchGroups(produto){
      let response = await axios.get(`${api.url}/group-optional/client/${this.idu}`);
        
       produto.groups.forEach(a => {
            a.mensagem = false
            a.obrigatorio = response.data.filter(b => a.id == b.id)[0].obrigatorio
            this.fetchOptional(a);
        })
      produto.groups.forEach(a => {
            a.mensagem = false
            this.fetchOptional(a);
        })
    },
    async fetchOptional(group){
      let response = await axios.get(`${api.url}/opcionais/client/${group.id}`);
      group.opcionais = response.data
    }
  },
  data() {
    return {
      dadosBanner: {},
      categorias: [],
      aberto: false, // Variável de exemplo para verificar se está aberto ou fechado
      dadosempresa: {
       
      },
      idu:  0
    };
  },
  async mounted() {
    let companyName = this.$route.params.companyName
    let response = await axios.get(`${api.url}/company/check/${companyName}`);
    this.idu = response.data.id
    this.fetchEmpresa();
   await this.fetchCategorias();
  }
};
</script>

<style>
@import "../../../src/assets/css/slim.css";
</style>
