<template>
<div>
    <div v-if="excluido" class="alert alert-danger" role="alert">
      <i class="fa fa-times" aria-hidden="true"></i> Produto excluído do pedido.
    </div>
 
  
  <div class="card card-people-list pd-15 mg-b-10" >
    <div class="slim-card-title tx-14"><i class="fa fa-caret-right"></i> Resumo do seu pedido   <i class="fa fa-shopping-cart tx-15"></i></div>
    
    <div align="center"><span class="tx-12">Comanda nº </span></div>
    <hr />
    <div class="media-list" style="margin-top:-1px;">
      <div v-for="carpro in pedidoItem.produtos" :key="carpro.uuid" :id="'accordion' + carpro.id" class="accordion-one mg-b-10" role="tablist" aria-multiselectable="true">
        <div> 
            <strong class="tx-14">{{ carpro.nome }}</strong>
            <br />
             <strong class="tx-11"> Quantidade:</strong> <span class="tx-11">  {{ carpro.quantidade }}</span>  <br />
             <strong class="tx-11" v-if="parseFloat(carpro.valor) > 0"> Valor. Unitario : </strong> <span class="tx-11" v-if="parseFloat(carpro.valor) > 0"> {{carpro.valor }} <br /></span>
             <strong class="tx-11"> Valor. Total : </strong> <span class="tx-11"> {{ formatPrice(parseFloat(carpro.valor) + parseFloat(adicionais(carpro).reduce((sum, item) => sum + parseFloat(item.valor), 0))) }}</span><br />
             <strong class="tx-11" v-if="carpro.obs"> Obs: </strong>  <span class="tx-11" v-if="carpro.obs">{{ carpro.obs }} <br /></span>
              
               <strong class="tx-12" v-if="adicionais(carpro).length > 0"> Adicionais </strong>
              <div v-for="adicional in adicionais(carpro)" :key="adicional.id">
               
                  <span class="tx-11">{{adicional.name}} - {{formatPrice(adicional.valor)}}</span>
              </div> 
           <div align="right" style="margin-top:5px">
               <a @click="removerItem(carpro)" style="color:#CC0000; cursor: pointer"><i class="fa fa-minus-square mg-r-5 tx-danger tx-12"></i><span class="tx-14">Excluir</span></a>
          </div>
           <hr />
     
        </div>
      </div>
    </div>
    
    <hr>
    <div class="row">
      <div class="col-6 tx-15">SubTotal</div>
      <div class="col-6 tx-15">{{formatPrice(subtotal)}}  </div>
    </div>
    
    <div class="row mg-t-10">
      <div class="col-6 tx-15">Adicionais</div>
      <div class="col-6 tx-15">{{formatPrice(adicionaisVlr)}}  </div>
    </div>
    
    <div class="row mg-t-10">
      <div class="col-6 tx-16"><strong>Total Parcial</strong></div>
      <div class="col-6 tx-16"><strong>{{formatPrice(totalParcial)}}  </strong></div>
    </div>
    <hr>
    <div class="tx-12" v-if="!pdv || !showFinal">
      <button @click="enviarPedido()" v-if="this.pedidoItem != null && this.pedidoItem.produtos != null && this.pedidoItem.produtos.length > 0" class="btn btn-success btn-block mg-b-10">ENVIAR PEDIDO</button>
      <span>Até o fechamento de sua comanda o valor do pedido poderá sofrer alteração caso haja pedidos adicionais.</span>
    </div>
    <div class="tx-12" v-else>
      <button @click="enviarPedido()"  class="btn btn-success btn-block mg-b-10">PEDIDO DELIVERY</button>
      <button @click="enviarBalcao()" class="btn btn-purple btn-block mg-b-10">PEDIDO BALCÃO</button>
      <button @click="enviarMesa()" class="btn btn-danger btn-block mg-b-10">PEDIDO MESA</button>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  props: ['produtos','pedido', 'remove', 'pdv','enviar','showFinal'],
   mounted() {
    this.pedidoItem = this.pedido
    if(this.pedidoItem != null && this.pedidoItem.produtos != null){
       this.calcule(this.pedido)
    }
  },
  methods: {
    calcule(pedido){
      this.pedidoItem = pedido
      this.totalParcial = 0
      this.adicionaisVlr = 0
      this.subtotal = 0
      if(this.pedidoItem != null && this.pedidoItem.produtos != null){
        this.total()
        this.totalAdcionais()
        this.subTotal()
      }
    },
    removerItem(produto) {
      const index = this.pedido.produtos.indexOf(produto);
        if (index > -1) {  
            this.remove(index)
             this.calcule(this.pedido)
        }
    },
    enviarPedido() {
      this.enviar('DELIVERY')
    },
    enviarBalcao() {
      this.enviar('BALCÃO')
    },
    enviarMesa() {
      this.enviar('MESA')
    },
    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },
    total(){
      this.pedidoItem.produtos.forEach(carpro => {
        this.totalParcial += (parseFloat(carpro.valor) + parseFloat(this.adicionais(carpro).reduce((sum, item) => sum + parseFloat(item.valor), 0))) 
      })
    },
     totalAdcionais(){
      this.pedidoItem.produtos.forEach(carpro => {
        this.adicionaisVlr += (parseFloat(this.adicionais(carpro).reduce((sum, item) => sum + parseFloat(item.valor), 0))) 
      })
    },
     subTotal(){
      this.pedidoItem.produtos.forEach(carpro => {
        this.subtotal += parseFloat(carpro.valor)
      })
    },
    formatPrice(price) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price);
    },
    adicionais(carpro){
      let ids = carpro.groups.filter(a => a.selectRadio > 0 || a.selectCheck > 0).map( a =>  a.selectRadio || a.selectCheck)
      return  carpro.groups.filter(a => a.selectRadio > 0 || a.selectCheck == true).map( a => a.opcionais.filter(b => ids.indexOf(b.id) > -1) ).flat()
    }
  },
   data() {
    return {
      collapsed: true,
      excluido: false,
      adicionaisVlr: 0,
      subtotal: 0,
      totalParcial: 0,
      pedidoItem: {}
    };

  },
};
</script>

<style scoped> 
.btn-purple {
    color: #fff;
    background-color: #6f42c1;
    border-color: #643ab0;
}
.btn-purple:hover {
    color: #fff;
    background-color: #5e37a6;
    border-color: #4e2d89;
}
</style>
