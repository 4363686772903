<style scoped>
@import "../../src/assets/css/slim.css";

.btn-oblong {
    border-radius: 50px;
}
.slim-navbar {
    padding: 15px 0;
    background-color: #fff;
    border-bottom: 1px solid #ced4da;
}
</style>
<template>

  <div id="app" class="signin-wrapper" style="background-image:url('img/bg.jpg');">
    <!-- Formulário de verificação de URL -->
    <div v-if="!cadastroFinalizado && !urlDisponivel" class="signin-box">
      <center>
        <img src="../../src/assets/img/logo.png" class="img-fluid" width="200">
        <br /><br />
        <h3 class="signin-title-secondary">CRIAR UMA NOVA CONTA</h3>
      </center>

      <p style="font-size:12px; margin-top:-40px;" align="justify">
        Informe um nome para sua URL sem espaços e sem acentos. Não será possível alterar este nome posteriormente.
      </p>

      <div class="form-group">
        <label><i class="fa fa-sign-out" aria-hidden="true" style="color:#0099FF"></i>https://deliveryexpress.tech/</label>
        <input type="text" v-model="url" class="form-control" placeholder="informe aqui o nome da empresa" maxlength="30" required>
        <p style="font-size:12px"><strong>Ex: reidopastel</strong></p>
      </div>

      <br>

      <button @click="verificarDisponibilidade" class="btn btn-primary btn-block btn-signin" style="margin-top:-20px;">
        Verificar Disponibilidade
      </button>

      <center v-if="mensagemErro">
        <strong style="color:#FF0000; font-size:14px; margin-top:-30px;">{{ mensagemErro }}</strong>
      </center>
    </div>

    <!-- Formulário de Cadastro -->
    <div v-if="urlDisponivel && !cadastroFinalizado" class="signin-box signup">
      <center>
        <img src="../../src/assets/img/logo.png" class="img-fluid" width="200">
        <br /><br />
        <h3 class="signin-title-secondary">CRIAR UMA NOVA CONTA</h3>
      </center>
      <div class="signup-separator" style="margin-top:-40px;"><span>SUA URL</span></div>
      <p align="center">https://deliveryexpress.tech/<span style="color:#FF0000">{{ url }}</span></p>
      <hr>
      <form @submit.prevent="finalizarCadastro">
        <input type="hidden" name="urlm" :value="urlMaster">
        <input type="hidden" name="urln" :value="url">
        <input type="hidden" name="modelon" :value="modelo">

        <div class="row row-xs mg-b-10">
          <div class="col-sm">
            <input type="text" name="empresa" v-model="empresa" class="form-control" placeholder="Nome da Empresa" maxlength="60" required>
            <label style="font-size:10px">Nome exibido no cardápio.</label>
          </div>
          <div class="col-sm mg-t-10 mg-sm-t-0">
            <input type="text" name="celular" v-model="celular" id="phone-number" class="form-control" placeholder="Nº de celular com Whatsapp" required>
            <label style="font-size:10px">Número que receberá o pedido.</label>
          </div>
        </div>

        <div class="row row-xs mg-b-10">
          <div class="col-sm">
            <input type="text" name="nome" v-model="nome" class="form-control" placeholder="Primeiro Nome" maxlength="30" required>
            <label style="font-size:10px">Nome para contato.</label>
          </div>
          <div class="col-sm mg-t-10 mg-sm-t-0">
            <input type="email" name="email" v-model="email" class="form-control" placeholder="Seu e-mail" maxlength="60" required>
            <label style="font-size:10px">E-mail para receber informações.</label>
          </div>
        </div>

        <div class="signup-separator" style="margin-top:20px;"><span>DADOS PARA ACESSO A CONTA</span></div>

        <div class="row row-xs mg-b-10">
          <div class="col-sm">
            <input type="text" name="cpf" v-model="cpf" id="cpf" class="form-control" placeholder="CPF ou CNPJ" required>
            <label style="font-size:10px">Será utilizado como login.</label>
          </div>
          <div class="col-sm mg-t-10 mg-sm-t-0">
            <input type="password" v-model="senha" class="form-control" name="senha" placeholder="Informe uma senha" maxlength="8" required>
            <label style="font-size:10px">Máximo de oito caracteres.</label>
          </div>
        </div>
        
        <button type="submit" class="btn btn-primary btn-block btn-signin">Criar Conta</button>
      </form>
    </div>

    <!-- Cadastro Finalizado -->
    <div v-if="cadastroFinalizado" class="signin-box signup">
      <center>
        <h2 class="slim-logo"><img src="../../src/assets/img/logo.png" class="img-fluid" width="200"></h2>
        <h3 class="signin-title-secondary">CADASTRO FINALIZADO</h3>
      </center>
      <div class="signup-separator" style="margin-top:-40px;"><span>IMPORTANTE</span></div>
      <p style="margin-top:10px; color:#666666; font-size:13px" align="justify">
        <strong>
          <span style="color:#00CCCC">Você terá <span style="color:#FF9966; font-size:18px">{{ diasTeste }} dias</span> gratuitos para testar todas as funcionalidades do nosso sistema.</span>
          <br />
          Obrigado por contratar nosso serviço. Abaixo seguem as instruções de acesso ao seu sistema.
        </strong>
      </p>
      <p v-if="novocli == 1" style="color:#00CC00; font-size:13px" align="justify"><strong>Sua conta já está liberada e pronta para ser utilizada.</strong></p>
      <p v-else style="color:#FF3333; font-size:13px" align="justify"><strong>Sua conta está sendo criada. Por favor, aguarde alguns minutos até receber uma mensagem informando a liberação do seu acesso.</strong></p>

      <div class="signup-separator" style="margin-top:20px;"><span>COPIE OS DADOS ABAIXO</span></div>

      <div class="row row-xs mg-b-10">
        <div class="col-sm">
          <strong>Sua url personalizada:</strong><br><span style="color:#006699">{{ urlMaster }}/{{ url }}</span><br><br>
          <strong>Painel Administrativo:</strong><br><span style="color:#006699">{{ urlMaster }}/{{ url }}/master</span><br>
        </div>
      </div>

      <div class="row row-xs">
        <div class="col-sm">
          <strong>Login:</strong> <span style="color:#006699">{{ cpf }}</span><br>
          <strong>Senha:</strong> <span style="color:#006699">****</span><br><br>
        </div>
      </div>

      <a href="../"><button class="btn btn-primary btn-block btn-signin mg-t-20">Finalizar</button></a>
    </div>
  </div>
</template>

<script>

import axios from 'axios';


export default {
  data() {
    return {
      url: '',
      mensagemErro: '',
      urlDisponivel: false,
      cadastroFinalizado: false,
      urlMaster: 'deliveryexpress.tech',
      modelo: 1,
      empresa: '',
      celular: '',
      nome: '',
      email: '',
      cpf: '',
      senha: '',
      diasTeste: 30, 
      nomesite: '',
      novocli: 1
    };
  },
  methods: {
    verificarDisponibilidade() {
      if (this.url) {
        axios.get(`https://gateway-0-0-0-1.onrender.com/company/check/${this.url}`)
          .then(response => {
            if (response.data.length == 0) {
              this.urlDisponivel = true;
            } else {
              this.mensagemErro = 'Este nome não está disponível.';
            }
          })
          .catch(error => {
            console.error('Erro na verificação:', error);
            this.mensagemErro = 'Erro ao verificar a disponibilidade.';
          });
      } else {
        this.mensagemErro = 'Por favor, informe um nome de URL.';
      }
    },
  }
}
  </script>


