<template>
  <div class="hello">
 <div v-if="!expired" class="slim-navbar" :style="`height:320px; background-image:url(${info.backgroundImage}); background-attachment:fixed; height: 100%; background-position: center; background-repeat: no-repeat; background-size: cover;`">
		<div class="container">
			<div class="row mg-t-20">
				
				<div class="col-md-4" align="center">
          
					<div class="mg-b-10"><img :src="info.logo" width="180" height="180" class="bd bd-3 rounded-20"></div>
										</div>
				<div class="col-md-8" align="center">
						<h2 class="mg-b-10"><span style="color:#FFFFFF; text-shadow: 2px 2px black;">{{empresa.nomeempresa}}</span></h2>
            <button v-if="empresa.delivery == 1" class="btn btn-success btn-oblong btn-sm"><i class="fa-regular fa-thumbs-up tx-20" aria-hidden="true"></i> ABERTO</button>
            <button v-else class="btn btn-danger btn-oblong btn-sm"><i class="fa-regular fa-thumbs-down tx-20" aria-hidden="true"></i> Fechado</button>
						<h6 class="mg-t-10">
						<!-- <a href="#" class="view_local" id="41">
							<span style="color:#FFFFFF; text-shadow: 2px 2px black;">
								<i class="fa fa-map-marker tx-20"></i>
							</span>
						</a>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<a href="https://api.whatsapp.com/send?phone=5522999235594&amp;text=Olá gostaria de falar com um atendente." target="_blanck">	
							<span style="color:#FFFFFF; text-shadow: 2px 2px black;">
								<i class="fab fa-whatsapp tx-30"></i>
							</span>
						</a>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<a href="#" class="view_hora" id="41">
							<span style="color:#FFFFFF; text-shadow: 2px 2px black;">
								<i class="fa fa-history tx-20"></i>
							</span>
						</a> -->
							
							
						</h6>

				</div>


		</div>
	</div>
</div>
    <b-modal v-model="modalShow" title="Detalhes do Produto" hide-footer>
      <DetalhesProduto :produto="produto"  :pedido="addPedido"></DetalhesProduto>
    </b-modal>
    <div  v-if="!expired" class="slim-mainpanel"> 
      <div class="container">
        <div class="row mg-t-10">
          <div class="col-md-3">
            <MenuComponent></MenuComponent>
          </div>
          <div class="col-md-6" v-if="listaPedido">
            <ItensComponent :openModal="openModal" :info="setEmpresa" :estilo="info"></ItensComponent>
          </div>
           <div v-else class="col-md-6">
            <EnderecoPedidoComponent ref="enderecoPedidoComponent" :pedido="pedido"></EnderecoPedidoComponent>
          </div>
          <div class="col-md-3">
            <PedidoComponent ref="pedidoComponent" v-if="empresa.delivery == 1" :showFinal="listaPedido" :enviar="enviar" :pedido="pedido" :remove="removeProduto" :info="info" :pdv="pdv"></PedidoComponent>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { api } from '../http/api';
import axios from 'axios';

import MenuComponent from './menu/MenuComponent.vue';
import ItensComponent from './itens/ItensComponent.vue';
import DetalhesProduto from '@/components/modal/DetalhesProduto'
import PedidoComponent from '@/components/pedidos/PedidoComponent'
import EnderecoPedidoComponent from '@/components/endereco/EnderecoPedidoComponent'

export default {
  components:{
    EnderecoPedidoComponent,
    ItensComponent,
    PedidoComponent,
    DetalhesProduto,
    MenuComponent
  },
  data() {
    return {
      listaPedido: true,
      info:{},
      pedido: {
        produtos: [],
        endereco:{},
      },
      companyId: null,
      pdv: false,
      expired: false,
      produto: null,
      modalShow: false,
      dadosfundo: {}, // Defina os dados de fundo aqui
      dadoslogo: {}, // Defina os dados do logotipo aqui
      empresa: {}, // Defina os dados da empresa aqui
      aberto: false, // Defina o estado de aberto/fechado aqui
      nomeCliente: 'Cliente', // Defina o nome do cliente aqui
      idu: 1 // Defina o ID do usuário aqui
    };
  },
  async  created(){
    // this.$ls.remove('pedido')

    let companyName = this.$route.params.companyName
    
    let response = await axios.get(`${api.url}/company/check/${companyName}`);
    this.companyId = response.data.id
    this.expired = response.data.expirado
    this.$ls.set('companyId', this.companyId)

    

    const urlParams = new URLSearchParams(window.location.search);
    this.pdv = urlParams.get('pdv');
    
    response = await axios.get(`${api.url}/style/client/${this.companyId}`);
    this.info = response.data
  
  }, 
    mounted (){
        let pedidoSalvo =  this.$ls.get('pedido')
        if (pedidoSalvo != null ) {
          this.pedido = JSON.parse(pedidoSalvo)
        }
    }
  
  ,
  methods: {
    openModal(produto) {
      this.modalShow = true
      this.produto = _.cloneDeep(produto); 
    },
    enviar(tipo){
      if(this.listaPedido){
        this.listaPedido = false
      }else {
        let ret = {}
        this.$refs.enderecoPedidoComponent.subimenter(ret)
         if(ret.isValid){
           this.pedido = {...this.pedido, ...ret.endereco }
           this.pedido.tipo = tipo
           this.$ls.set('pedido', JSON.stringify(this.pedido))
           this.enviarPedido()
         }
      }
    },
    enviarPedido(){
      this.pedido.companyId = 4
      axios.post(`${api.url}/request/client/save`, this.pedido)
          .then(() => {
            this.$ls.remove('pedido');
          })
          .catch(error => {
            console.error('Erro ao salvar os dados:', error);
          });
    },
    setEmpresa(empresa){
      this.empresa = empresa
    },
    close(){
      this.produto = {groups:[]}
      this.modalShow = false
      this.$refs.pedidoComponent.calcule(this.pedido);
    },
    removeProduto(index){
      this.pedido.produtos.splice(index, 1);
      this.$refs.pedidoComponent.calcule();
    },
    
    addPedido(produto){
      this.pedido.produtos.push(produto)
      this.$ls.set('pedido', JSON.stringify(this.pedido))
      this.close()
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../../src/assets/css/slim.css";

.btn-oblong {
    border-radius: 50px;
}
.slim-navbar {
    padding: 15px 0;
    background-color: #fff;
    border-bottom: 1px solid #ced4da;
}
</style>
