<template>
  <div>
    <b-container>
      <b-card>
        <div class="slim-card-title tx-18">
          <i class="fa fa-caret-right"></i> Dados do Cliente
        </div>
        <b-form ref="formulario" @submit.prevent="confirmarPedido">
          <div class="row mg-t-10">
            <div class="col-md-6 col-12">
              <b-form-group
                label="Forma de Pagamento:"
                label-for="formaPagamento"
                :state="validState('formaPagamento')"
                invalid-feedback="Por favor, selecione a forma de pagamento."
              >
                <b-form-select
                  id="formaPagamento"
                  v-model="formData.formaPagamento"
                  :options="formasPagamento"
                  required
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-6 col-12">
              <b-form-group
                label="Precisa de Troco?:"
                label-for="troco"
                :state="validState('troco')"
                invalid-feedback="Informe o valor do troco."
              >
                <b-form-input
                  id="troco"
                  v-model="formData.troco"
                  :disabled="formData.formaPagamento !== 'DINHEIRO'"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="row mg-t-10">
            <div class="col-md-8 col-12">
              <b-form-group
                label="Rua:"
                label-for="endereco"
                :state="validState('endereco')"
                invalid-feedback="Por favor, informe a rua."
              >
                <b-form-input
                  id="endereco"
                  v-model="formData.endereco"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4 col-12">
              <b-form-group
                label="Número:"
                label-for="numero"
                :state="validState('numero')"
                invalid-feedback="Informe o número do endereço."
              >
                <b-form-input
                  id="numero"
                  v-model="formData.numero"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-12 col-12">
              <b-form-group
                label="Bairro:"
                label-for="bairro"
                :state="validState('bairro')"
                invalid-feedback="Por favor, informe o bairro."
              >
                <b-form-input
                  id="bairro"
                  v-model="formData.bairro"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="row mg-t-10">
            <div class="col-md-6 col-12">
              <b-form-group
                label="Cidade:"
                label-for="cidade"
                :state="validState('cidade')"
                invalid-feedback="Informe a cidade."
              >
                <b-form-input
                  id="cidade"
                  v-model="formData.cidade"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 col-12">
              <b-form-group
                label="Estado:"
                label-for="estado"
                :state="validState('estado')"
                invalid-feedback="Informe o estado."
              >
                <b-form-input
                  id="estado"
                  v-model="formData.estado"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 col-12">
              <b-form-group
                label="Complemento:"
                label-for="complemento"
                :state="validState('complemento')"
                invalid-feedback="Informe o complemento se necessário."
              >
                <b-form-input
                  id="complemento"
                  v-model="formData.complemento"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="row mg-t-10">
            <div class="col-md-12 col-12">
              <b-form-group
                label="Observação:"
                label-for="observacao"
                :state="validState('observacao')"
                invalid-feedback="Informe uma observação, se necessário."
              >
                <b-form-input
                  id="observacao"
                  v-model="formData.observacao"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 col-12">
              <b-form-group
                label="Nº do seu WhatsApp:"
                label-for="whatsapp"
                :state="validState('whatsapp')"
                invalid-feedback="Por favor, informe um número de WhatsApp válido."
              >
                <b-form-input
                  id="whatsapp"
                  v-model="formData.whatsapp"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 col-12">
              <b-form-group
                label="Primeiro Nome:"
                label-for="primeiroNome"
                :state="validState('primeiroNome')"
                invalid-feedback="Por favor, informe o primeiro nome."
              >
                <b-form-input
                  id="primeiroNome"
                  v-model="formData.primeiroNome"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

            <div class="col-md-6 col-12">
              <b-button ref="btnSubmit" hidden type="submit" variant="success">Confirmar Pedido</b-button>
            </div>
           
        </b-form>
      </b-card>
    </b-container>

    <b-modal id="modal-confirmacao" title="Obrigado pela compra!" @ok="enviarWhatsapp">
      Seu pedido foi confirmado com sucesso! Você será redirecionado ao WhatsApp para finalizar a compra.
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ['pedido'],
  data() {
    return {
      allValid: false,
      formData: {
        endereco: this.pedido.endereco || "",
        numero: this.pedido.numero || "",
        bairro: this.pedido.bairro || "",
        cidade: this.pedido.cidade || "",
        estado: this.pedido.estado || "",
        observacao: this.pedido.observacao || "",
        whatsapp: this.pedido.whatsapp || "",
        primeiroNome: this.pedido.primeiroNome || "",
        formaPagamento: this.pedido.formaPagamento || "Dinheiro",
        troco: this.pedido.troco || "0,00",
      },
      formasPagamento: [
        { value: "DINHEIRO", text: "Dinheiro" },
        { value: "CARTAO", text: "Cartão" },
      ],
      valid: true, // Flag para verificar se todos os campos estão válidos
    };
  },
  methods: {
    validState(field) {
      return this.formData[field] && this.formData[field].length > 0 ? true : null;
    },
    subimenter(ret){
      this.$refs.btnSubmit.click()
      ret.isValid = this.allValid
      ret.endereco = this.formData
    },
    confirmarPedido() {
      // Validação final
      
      const fields = [
        'endereco',
        'numero',
        'bairro',
        'cidade',
        'estado',
        'whatsapp',
        'primeiroNome',
        'formaPagamento',
        'troco'
      ];
      this.allValid = true
      fields.forEach(field => {
        if (!this.formData[field] || this.formData[field].length === 0) {
          this.allValid = false;
        }
      });

      if (this.allValid) {
        // Mostrar modal de confirmação
        this.$bvModal.show('modal-confirmacao');
      } else {
        this.valid = false; 
        return false// Marcar como inválido para alterar o estilo
      }
      return true
    },
    enviarWhatsapp() {
      const telefone = "551433449103";
      const mensagem = `
        🔔 Segue o pedido

        *${this.idPedido}*

        Nome: *${this.formData.primeiroNome}*

        Pedido:
        *Qtd:* 1x Brócolis
        *Valor:* R$ 25,00
        *OBS:* Não

        *Endereço:*
        Rua: ${this.formData.endereco}, N°: ${this.formData.numero},
        Bairro: ${this.formData.bairro || ''},
        Cidade: ${this.formData.cidade}, ${this.formData.estado},
        Complemento: ${this.formData.observacao || '*Não informado*'}
        OBS: *Não informado*

        *Pagamento:* ${this.formData.formaPagamento}
        *SubTotal:* R$ 26,00

        Prazo para entrega: *60 minutos*`;

         window.open(`https://wa.me/${telefone}?text=${encodeURIComponent(mensagem)}`, '_blank');
    },
  },
};
</script>

<style scoped>
.form-group input.is-invalid, 
.form-group select.is-invalid {
  border-color: red;
}
</style>
